import React from 'react';
import { Helmet } from 'react-helmet';
import PricingForm from '../components/pricingForm';

const PricingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Pricing Page - Currently FREE</title>
        <meta content="Currently FREE. No credit card required. No hidden fees. No strings attached." />
      </Helmet>
      <PricingForm />
    </div>
  );
};

export default PricingPage;
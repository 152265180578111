import React, { useState, useCallback } from 'react';
import axios from 'axios';
import CustomPopup from './customPopup';

function Bookmark() {
    const [showPopup, setShowPopup] = useState(false);
    const [passcode, setPasscode] = useState('');
    const [videoIDs, setVideoIDs] = useState([]);
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    console.log('Base URL:', baseURL);

    const handleAuth = useCallback(async () => {
        console.log('Sending passcode:', passcode);
        try {
            const response = await axios.get(`${baseURL}/api/secret/videos`, {
                headers: { 'admin-passcode': passcode }
            });
            console.log('Response from server:', response.data);
            setVideoIDs(response.data);
            setShowPopup(false);
            setPasscode('');
        } catch (error) {
            console.error('Error during authentication:', error.response ? error.response.data : error.message);
            CustomPopup('Failed to authenticate or fetch data');
            setPasscode('');
        }
    }, [ passcode]);

    const handleCancel = () => {
        setShowPopup(false);
        setPasscode('');
        setVideoIDs([]);
    };

    return (
        <div>
            <button 
                onClick={() => setShowPopup(true)} 
                style={{
                    width: '30px', // Width of the bookmark
                    height: '30px', // Height of the bookmark
                    backgroundColor: 'black', // Color of the bookmark
                    clipPath: 'polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%)', // Shape of the bookmark
                    border: 'none', // No border
                    cursor: 'pointer', // Cursor pointer on hover
                    transition: 'height 0.3s ease' // Smooth transition for height change
                }}
                onMouseEnter={e => e.currentTarget.style.height = '50px'} // Increase height on hover
                onMouseLeave={e => e.currentTarget.style.height = '30px'} // Revert height on mouse leave
            >
                {/* You can use an icon or leave it empty if you want just the shape */}
            </button>
            {showPopup && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                        <h2 className="text-xl font-bold mb-4">Enter Admin Passcode</h2>
                        <input
                            type="password"
                            value={passcode}
                            onChange={(e) => setPasscode(e.target.value)}
                            placeholder="Enter Admin Passcode"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                onClick={handleCancel}
                                className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAuth}
                                className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Submit
                            </button>
                        </div>
                        <ul className="mt-4">
                            {videoIDs.map(id => (
                                <li key={id}>{id}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
}

export default React.memo(Bookmark);


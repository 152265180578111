import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

// Sample data for FAQ questions and answers
const faqData = [
  { question: "What is this function?", answer: "Upload video to client, comments and amendment all in one place." },
  { question: "Do I need login/sigup?", answer: "No need at all. Everyone go in anonymously." },
  { question: "How much does it cost?", answer: "It's free until you feel to need higher usage of video file upload and storage." },
  // { question: "My tech stack is different, can I still use it?", answer: "Yes, our product is highly adaptable to different tech stacks." },
  // { question: "Is it a website template?", answer: "Yes, it's a fully designed website template." },
  // { question: "How is ShipFast better than other boilerplates?", answer: "ShipFast is optimized for performance and scalability." },
  // { question: "Are there any other costs associated?", answer: "No, all costs are upfront with no hidden fees." },
  // { question: "How often is ShipFast updated?", answer: "We update monthly to ensure you have the latest features." },
  // { question: "Can I get a refund?", answer: "Yes, we offer a 30-day money-back guarantee." },
  // { question: "Can I use PayPal?", answer: "Yes, PayPal is one of our accepted payment methods." }
];

function QnAPage() {
  const style ={
    btnReturn: {
      backgroundColor: '#4F46E5', // indigo-700 color
      color: '#fff', //white
      border: 'none',
      padding: '10px', // Circular padding
      cursor: 'pointer',
      borderRadius: '50%', // Fully round shape
      width: '40px', // Fixed width for circle
      height: '40px', // Fixed height for circle
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20px', // Add space between form and button
      transition: 'background-color 0.3s', // Smooth transition for hover effect
    },
    btnReturnHover: {
      backgroundColor: '#71717a', // gray color on hover
    },
  }

  const [expandedStates, setExpandedStates] = useState(faqData.map(() => false));
  const contentRefs = useRef(faqData.map(() => React.createRef()));

  const toggleFAQ = (index) => {
    const newStates = [...expandedStates];
    newStates[index] = !newStates[index];
    setExpandedStates(newStates);
  };

  const [hoverReturn, setHoverReturn] = useState(false);
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate('/', { replace: true });
  }

  return (
    <div>
      <Helmet>
        <title>Do not want multiple files?</title>
        <meta content="Use JustShareNow to share files, client comments and amendment upload. Fast and Efficient." />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h1>
      <div className="flex flex-col space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="bg-indigo-700 text-white p-3 rounded-lg overflow-hidden">
            <button
              className="w-full text-left font-semibold"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span className="float-right">{expandedStates[index] ? '-' : '+'}</span>
            </button>
            <div style={{
              maxHeight: expandedStates[index] ? `${contentRefs.current[index].current.scrollHeight}px` : '0',
              transition: 'max-height 0.7s ease-in-out',
              overflow: 'hidden'
            }}>
              <div ref={contentRefs.current[index]} className="p-2 bg-transparent text-white rounded-b">
                <p>{faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-8">
        <p>Have another question? Contact me on <a href="https://www.instagram.com/hs_0215/" className="text-blue-500">Instagram</a> or by <a href="mailto:sheldontan.work@gmail.com" className="text-blue-500">email</a>.</p>
      </div>
      <div className="flex justify-center mt-4">
        <button style={{ ...style.btnReturn, ...(hoverReturn ? style.btnReturnHover : {}) }}
          onMouseEnter={() => setHoverReturn(true)}
          onMouseLeave={() => setHoverReturn(false)}
          onClick={handleReturn}>
          <span style={{ fontSize: '16px', color: 'white' }}>←</span>
        </button>
      </div>
    </div>
    </div>
  );
}

export default QnAPage;
import React from 'react';

function CustomPopup({ isOpen, message, onClose }) {
    if (!isOpen) return null;

    return (
        <div style={{
            position: 'fixed',
            top: '50%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Adjust both horizontally and vertically
            backgroundColor: 'white',
            color: 'black',
            padding: '20px',
            zIndex: 1001,
            borderRadius: '12px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            textAlign: 'center', // Center align the text
            width: 'auto', // Adjust width as necessary
            maxWidth: '90%', // Ensure it doesn't exceed the screen width
            boxSizing: 'border-box' // Include padding in width calculation
        }}>
            <p style={{ fontWeight: 'bold' }}>{message}</p>
            <button onClick={() => onClose()} style={{
                display: 'block',
                margin: '10px auto 0',
                padding: '5px 10px',
                fontSize: '16px',
                borderRadius: '5px',
                cursor: 'pointer',
                backgroundColor: 'gray',
                color: 'white'
            }}>Close</button>
        </div>
    );
}

export default CustomPopup;
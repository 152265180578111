import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import VideoPlayer from '../components/videoPlayers';
import CommentSection from '../components/commentSection';
import ButtonNav from '../components/buttonNav';
import { VideoContext } from '../context/videoContext';
import { CommentContext } from '../context/commentContext';
import DescriptionSection from '../components/descriptionSection';
import CustomPopup from '../components/customPopup';


function VideoPage() {
  const { id } = useParams();  // Correctly using 'id' from useParams
  useContext(VideoContext);
  const { comments, fetchComments, addComment, deleteComment } = useContext(CommentContext);
  // const [currentVersion, setCurrentVersion] = useState('v1');  // Default to v1
  const [description, setDescription] = useState(''); // State to hold the description
  // const [videoUrl, setVideoUrl] = useState('');
  // const [video, setVideo] = useState(null);  // Define video in state
  const videoPlayerRef = useRef(null);
  const [videoState, setVideoState] = useState({
    currentVersion: 'v1',
    video: null,
    videoUrl: '',
    isLoading: false,
    error: null,
  });
  const { currentVersion, video, videoUrl } = videoState;
  const [popup, setPopup] = useState({ isOpen: false, message: '' });
  const closePopup = () => {
    setPopup({ isOpen: false, message: '' });
  };
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  

  useEffect(() => {
    console.log('Effect running: fetching video data for videoId', id);

    const fetchVideoByVersion = async () => {
      try {
        const url = `${baseURL}/api/videos/${id}/version/${currentVersion}`;
        const response = await axios.get(url);
        console.log("API Response:", response.data);
        setVideoState({
          currentVersion: response.data.version,
          video: response.data,
          videoUrl: response.data.url,
          isLoading: false,
          error: null,
        });
        setDescription(response.data.description); // Make sure this line is executing
        console.log("Description set:", response.data.description);
      } catch (error) {
        console.error('Error fetching video:', error);
        setPopup({ isOpen: true, message: 'Error fetching video. Please try again.' });
      }
    }; // Dependencies added here

    fetchVideoByVersion();
    
  }, []); // Dependency on the useCallback function

  // useEffect(() => {
  //   if (video) {
  //     console.log("Video ID set in state:", video.id);  // Make sure the property is 'id' and not '_id' or another name
  //   }
  // }, [video]);

  const updateDescription = (newDescription) => {
    setDescription(newDescription);
  };
  
  // const handleDeleteVideoVersion = async () => {
  //   if (currentVersion === 'v1') {
  //     alert('Version v1 cannot be deleted.');
  //     return;
  //   }

  //   try {
  //     await axios.delete(`http://localhost:5001/api/videos/${id}/version/${currentVersion}`);
  //     alert('Video version deleted successfully.');
  
  //     // Fetch the remaining versions of the video
  //     const response = await axios.get(`http://localhost:5001/api/videos/${id}`);
  //     if (response.data && response.data.versions && response.data.versions.length > 0) {
  //       // Update to the next available version or the last one
  //       const nextVersion = response.data.versions[0]; // Assuming versions are sorted or you sort them
  //       setCurrentVersion(nextVersion.version);
  //       setVideo(nextVersion);
  //       setVideoUrl(nextVersion.url);
  //     } else {
  //       // No more versions available
  //       setVideo(null);
  //       setVideoUrl('');
  //       alert('No more versions available.');
  //       navigate('/'); // Optionally navigate away if no versions are left
  //     }
  //   } catch (error) {
  //     console.error('Error deleting video version:', error);
  //     alert('Failed to delete the video version.');
  //   }
  // };

  const handleNext = async () => {
    const currentVersionNumber = parseInt(currentVersion.substring(1));
    const nextVersionNumber = currentVersionNumber + 1;
    const nextVersion = `v${nextVersionNumber}`;

    try {
      const url = `${baseURL}/api/videos/${id}/version/${nextVersion}`;
      const response = await axios.get(url);
      if (response.data && response.data.url) {
        console.log(`Version ${nextVersion} found, updating state.`);
        setVideoState({
          currentVersion: nextVersion,
          video: response.data,
          videoUrl: response.data.url,
          isLoading: false,
          error: null,
        });
      } else {
        console.log(`No data returned for version ${nextVersion}.`);
        setPopup({ isOpen: true, message: 'No next version available.' });
      }
    } catch (error) {
      console.error(`Error fetching version ${nextVersion}:`, error);
      setPopup({ isOpen: true, message: 'Failed to fetch the next version of the video.' });
    }
  };

  const handlePrevious = async () => {
    const currentVersionNumber = parseInt(currentVersion.substring(1));
    const prevVersionNumber = currentVersionNumber - 1;
    if (prevVersionNumber < 1) {
      setPopup({ isOpen: true, message: 'This is the first version of the video.' });
      return;
    }
    const prevVersion = `v${prevVersionNumber}`;

    try {
      const url = `${baseURL}/api/videos/${id}/version/${prevVersion}`;
      const response = await axios.get(url);
      if (response.data && response.data.url) {
        console.log(`Version ${prevVersion} found, updating state.`);
        setVideoState({
          currentVersion: prevVersion,
          video: response.data,
          videoUrl: response.data.url,
          isLoading: false,
          error: null,
        });
      } else {
        console.log('No previous version found.');
        setPopup({ isOpen: true, message: 'No previous version available.' });
      }
    } catch (error) {
      console.error('Error fetching previous version:', error);
      setPopup({ isOpen: true, message: 'Failed to fetch the previous version of the video.' });
    }
  };
  
  useEffect(() => {
    if (id && currentVersion) {
      fetchComments(id, currentVersion);
    }
  }, [id, currentVersion, fetchComments]);

  if (!video && !videoState.error) {
    return <div>Loading video...</div>;
  }
  
  if (videoState.error) {
    return <div>{videoState.error}</div>;
  }

  const handleSaveAndExit = () => {
    console.log("handleSaveAndExit called");  // Debug: Check if function is called
    console.log("Current popup state before setting:", popup);  // Debug: Log current state

    let timeoutHandle = setTimeout(() => {
      navigate('/', { replace: true });
    }, 3000);

    // Set the popup to show a completion message
    setPopup({
      isOpen: true,
      message: 'Save completed! Redirecting...',
      onClose: () => {
        clearTimeout(timeoutHandle); // Clear the timeout to prevent navigation if the popup is closed manually
        navigate('/', { replace: true });
      }
    });

    console.log("Popup state set to:", { isOpen: true, message: 'Save completed! Redirecting...' });  // Debug: Confirm state set
};
  
  const handleTimestampClick = (time) => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekToTime(time);
    }
  };

  console.log(video.videoUrls); // Ensure video is defined before logging
  console.log("Video title:", video.title);
  console.log("Video description:", video.description);
  console.log("VideoPage - videoId:", video.id, "version:", currentVersion);

  return (
    <div className="container mx-auto p-4 h-screen flex flex-col">
      <CustomPopup isOpen={popup.isOpen} message={popup.message} onClose={closePopup} />
      <nav className="flex justify-between items-center p-4">
        <h1 className="text-xl font-bold text-center flex-1">{video.title}</h1>
        <button onClick={handleSaveAndExit} className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          Save and Exit
        </button>
      </nav>
      <div className="flex flex-row justify-between items-stretch flex-grow">
        <div className="video-container flex-1 bg-white rounded-lg shadow-lg m-2 overflow-hidden flex flex-col justify-center items-center">
          <VideoPlayer ref={videoPlayerRef} videoUrl={videoUrl}/>
          <div className="video-navigation mt-4 flex justify-center">
            <div className="flex justify-center my-4">
              <button onClick={handlePrevious} 
                className="inline-flex justify-center py-2 px-4 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >&#8592;</button>
              <button onClick={handleNext}
                className='inline-flex justify-center py-2 px-4 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >&#8594;</button>
            </div>
          </div>
        </div>
        <div className="comment-container flex-1 bg-white rounded-lg shadow-lg m-2 overflow-visible flex flex-col p-4 justify-between">
          <CommentSection videoId={video.id} version={currentVersion} comments={comments} addComment={addComment} deleteComment={deleteComment} onTimestampClick={handleTimestampClick} />
          <div className="mt-auto">
            <ButtonNav videoId={video.id} updateDescription={updateDescription} />
          </div>
        </div>
      </div>
      <div className="description-container bg-white rounded-lg shadow-lg m-2 p-4" style={{ height: 'auto', display: 'flex' }}>
        <DescriptionSection description={description} />  
      </div>
    </div>
  );
}


export default React.memo(VideoPage);

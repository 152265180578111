/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import logo from '../sources/JustShareNow.png'; // Replace with the actual path to your logo
import ContactForm from './contactForm';

const navbarStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
  color: white;
`;

const logoStyle = css`
  width: 150px; /* Adjust based on your logo size */
`;

const navLinksStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: black ; 
  border-radius: 10px; /* Rounded corners */
  padding: 0.5rem 1rem; /* Padding inside the navigation links container */
`;

const navFeature = css`
  margin: 0 1rem;
  text-decoration: none;
  color: white ;
  font-weight: 500; /* Make text bolder */
  &:hover {
    color: #4F46E5; 
  }
`;

const buttonStyle = css`
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  background-color: white;
  color: #333;
  font-weight: 500;
  border: none;
  border-radius: 0.5rem;
  &:hover {
    background-color: #4F46E5;
    color: white; /* Text color changes to white on hover */
  }
`;


function Navbar() {
 const [showContactForm, setShowContactForm] = useState(false);

 const handleContactClick = (e) => {
  e.preventDefault();
  setShowContactForm(true);
 }


  return (
    <nav css={navbarStyle}>
      <div>
        <img src={logo} alt="Logo" css={logoStyle} />
      </div>
      <div style={{ flexGrow: 1 }}></div> {/* Empty space in the middle */}
      <div css={navLinksStyle}>
        {/* <Link to="/features" css={navFeature}>Features</Link> */}
        <Link to="/pricing" css={navFeature}>Pricing</Link>
        <Link to="/contact" css={navFeature} onClick={handleContactClick}>Contact</Link>
        <Link to="/QnA" css={buttonStyle}>QnA</Link>
        {/* <Link to="/sign-up" css={buttonStyle}>Sign up</Link> */}
      </div>
      {showContactForm && <ContactForm onClose={() => setShowContactForm(false)} />}
    </nav>
  );
}

export default Navbar;

import React, { createContext, useState, useCallback } from 'react';
import axios from 'axios';

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [videos, setVideos] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL; // Use environment variable

  const fetchVideos = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/api/videos`);
      setVideos(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [baseURL]);

  const deleteVideoByVersion = async (videoId, version) => {
    try {
      const response = await axios.delete(`${baseURL}/api/videos/${videoId}/version/${version}`);
      console.log('Delete response:', response.data);
    } catch (error) {
      console.error('Failed to delete video version:', error);
    }
  };

  return (
    <VideoContext.Provider value={{ videos, fetchVideos, deleteVideoByVersion }}>
      {children}
    </VideoContext.Provider>
  );
};
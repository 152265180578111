import React from 'react';
import styles from '../reuse/loader/Loader.module.css';

const Loader = ({ progress, loading }) => (
  <div className={styles.loadingText}>
    {loading ? (
      <span className={styles.loadingTextWords}>{progress}%</span>
    ) : (
      <span className={styles.loadingTextWords}>Upload Complete</span>
    )}
  </div>
);
    /* <span className={styles.loadingTextWords}>U</span>
    <span className={styles.loadingTextWords}>P</span>
    <span className={styles.loadingTextWords}>L</span>
    <span className={styles.loadingTextWords}>O</span>
    <span className={styles.loadingTextWords}>A</span>
    <span className={styles.loadingTextWords}>D</span>
    <span className={styles.loadingTextWords}>I</span>
    <span className={styles.loadingTextWords}>N</span>
    <span className={styles.loadingTextWords}>G</span> */


export default Loader;
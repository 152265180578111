import React, { useState } from 'react';
import axios from 'axios';
import Loader from './loader'; // Import the Loader component
import CustomPopup from './customPopup';

function ContactForm({ onClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [inquiry, setInquiry] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to handle loading
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [popup, setPopup] = useState({
    isOpen: false,
    message: ''
});
const closePopup = () => {
  setPopup({
      isOpen: false,
      message: ''
  });
  onClose();
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when the form submission starts
    try {
      await axios.post(`${baseURL}/api/contact/contactForm`, { name, email, message: inquiry });
      setPopup({ isOpen: true, message: 'Your inquiry has been sent successfully.' });
      setName('');
      setEmail('');
      setInquiry('');
    } catch (error) {
      console.error('Error sending inquiry:', error);
      setPopup({ isOpen: true, message: 'Failed to send inquiry. Please try again later.' });
    }
    setIsLoading(false); // Set loading to false after the form submission is handled
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-xl font-bold mb-4 text-black">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Inquiry</label>
            <textarea
              value={inquiry}
              onChange={(e) => setInquiry(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              required
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            {isLoading ? (
              <Loader /> // Display Loader when isLoading is true
            ) : (
              <button
                type="submit"
                className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            )}
          </div>
        </form>
        <CustomPopup isOpen={popup.isOpen} message={popup.message} onClose={closePopup} />
      </div>
    </div>
  );
}

export default ContactForm;
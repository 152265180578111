import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from './loader'; // Ensure correct import path
import NotifyButton from './notifyButton'; // Ensure correct import path
import CustomPopup from './customPopup';

function VideoUpload() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [fileError, setFileError] = useState('');
  const [popup, setPopup] = useState({
    isOpen: false,
    message: '',
  });
  const closePopup = () => {
    setPopup({
      isOpen: false,
      message: '',
    });
    setTitle('');
    setDescription('');
    setFile(null);
  };

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedTypes = ['video/mp4', 'video/quicktime']; // MIME types for MP4 and MOV
      if (!allowedTypes.includes(selectedFile.type)) {
        setPopup({
          isOpen: true,
          message: 'Only MP4 and MOV video files are allowed.',
        });
        return;
      }
      if (selectedFile.size > 1073741824) { // 1GB in bytes
        setPopup({
          isOpen: true,
          message: 'File size cannot exceed 1GB',
        });
        setModalContent(
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <p style={{ marginBottom: '20px' }}>{fileError}</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <NotifyButton />
            </div>
            <button style={{ marginTop: '20px' }} onClick={() => {
              setShowModal(false);
              setFileError('');
              setTitle('');
              setDescription('');
              e.target.value = null;
            }}>Clear</button>
          </div>
        );
      } else {
        setFile(selectedFile);
        setFileError('');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setPopup({
        isOpen: true,
        message: 'Please select a file to upload.',
      });
      return;
    }

    const allowedTypes = ['video/mp4', 'video/quicktime'];
    if (!allowedTypes.includes(file.type)) {
      setPopup({
        isOpen: true,
        message: 'Only MP4 and MOV video files are allowed.',
      });
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('video', file);

    try {
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.post(`${baseURL}/api/videos/upload`, formData, {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });

    
    // setPopup({
    //   isOpen: true,
    //   message: (
    //     <>
    //       <p>Video uploaded successfully! Click here to view your video or navigate to the id: {response.data._id}</p>
    //       <a href={`${baseURL}/api/videos/${response.data._id}`} target="_blank" style={{ color: 'blue', textDecoration: 'underline' }}>
    //         View Video
    //       </a>
    //     </>
    //   ),
    // });
      setPopup({
        isOpen: true,
        message: `Video uploaded successfully! Video ID: ${response.data._id}`,
      });
    } catch (error) {
      setPopup({
        isOpen: true,
        message: `Error uploading video. Please try again later`,
      });
    } finally {
      setIsUploading(false);
      setTitle('');
      setDescription('');
      setFile(null);
      e.target.reset();
      setUploadProgress(0); // Reset progress after upload is complete
    }
  };

  return (
    <div className="flex justify-center items-center h-90">
      <form onSubmit={handleSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow-lg h-full">
        <div>
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Title"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Caption</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Caption"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Video</label>
          <input
            type="file"
            onChange={handleFileChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="flex justify-center w-full">
          <button
            type="submit"
            className={`py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isUploading ? 'font-bold' : ''}`}
            disabled={isUploading}
          >
            {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <Loader progress={uploadProgress} loading={isUploading} />
          </div>
            )}
            Upload
          </button>
        </div>
      </form>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            {modalContent}
            <div className="flex justify-center">
              <button onClick={() => setShowModal(false)} className="mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <CustomPopup isOpen={popup.isOpen} message={popup.message} onClose={closePopup} />
    </div>
  );
}

export default VideoUpload;
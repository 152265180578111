import React, { useState } from 'react';
import axios from 'axios';
import Loader from './loader'; 
import CustomPopup from './customPopup';

const StripePaymentForm = ({ onClose }) => {
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // Ensure it's above everything else
            cursor: 'default',
            pointerEvents: 'auto',
        },
        paymentForm: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '400px',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            zIndex: 1001 // Above the overlay
        },
        formGroup: {
            width: '100%',
            marginBottom: '0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        formGroupLabel: {
            marginBottom: '5px',
            textAlign: 'center'
        },
        formGroupInput: {
            width: '100%',
            padding: '8px',
            color: '#000',
            border: '1px solid #ccc',
            borderRadius: '4px',
        },
        submitButton: {
            padding: '10px 20px',
            color: '#fff',
            backgroundColor: '#007bff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            width: '50%', // Make buttons full width
            marginTop: '10px',
            transition: 'background-color 0.3s'
        },
        submitButtonHover: {
            backgroundColor: '#0056b3'
        },
        cancelButton: {
            padding: '10px 20px',
            color: '#fff',
            backgroundColor: '#dc3545',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            width: '50%', // Make buttons full width
            marginTop: '10px',
            transition: 'background-color 0.3s'
        },
        cancelButtonHover: {
            backgroundColor: '#c82333'
        },
        title: {
            fontSize: '24px',
            color: '#333',
            marginBottom: '10px',
            textAlign: 'center'
        },
        subtitle: {
            fontSize: '16px',
            color: '#666',
            marginBottom: '20px',
            textAlign: 'center'
        }
    };
    const [hoverSubmit, setHoverSubmit] = useState(false);
    const [hoverCancel, setHoverCancel] = useState(false);
    const [showPayment] = useState(true); // Assume this should be true initially for demonstration
    const [isLoading, setIsLoading] = useState(false);
    const [cardDetails, setCardDetails] = useState({
        email: '',
        name: '',
    });
    const [popup, setPopup] = useState({
        isOpen: false,
        message: ''
    });

    const closePopup = () => {
        setPopup({
            isOpen: false,
            message: ''
        });
        onClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCardDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading to true when the form submission starts
        console.log("Submitting form with data:", { email: cardDetails.email, name: cardDetails.name }); // Debug: log data being sent
        try {
            const baseURL = process.env.REACT_APP_API_BASE_URL;
            console.log("Base URL:", baseURL);
            await axios.post(`${baseURL}/api/contact/paidUser`, {
                email: cardDetails.email,
                name: cardDetails.name,
            });
            console.log("Form submission successful"); // Debug: log success
            setPopup({ isOpen: true, message: 'Your inquiry has been sent successfully.' });
            setCardDetails({ email: '', name: '', }); // Reset email after successful submission
        } catch (error) {
            console.error('Error sending inquiry:', error);
            setPopup({ isOpen: true, message: 'Failed to send inquiry. Please try again later.' });
        }
        setIsLoading(false); // Set loading to false after the form submission is handled
    };

    const handleCancel = () => {
        // Reset form and close the payment form popup
        setCardDetails({
            email: ''
        });
        onClose(); // Call the onClose function passed from the parent component
    };

    if (!showPayment) return null; // Don't render anything if not showing the payment form
    console.log("Popup state:", popup);

    return (
        <div style={styles.overlay}>
            <form onSubmit={handleSubmit} style={styles.paymentForm}>
                <h2 style={styles.title}>Are you trying or really interested to be a paid user?</h2>
                <p style={styles.subtitle}>If yes, please provide your name and email below to proceed.</p>
                <div style={styles.formGroup}>
                    <label style={styles.formGroupLabel}>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={cardDetails.name}
                        onChange={handleChange}
                        style={styles.formGroupInput}
                        placeholder="Enter your name here"
                    />
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.formGroupLabel}>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={cardDetails.email}
                        onChange={handleChange}
                        style={styles.formGroupInput}
                        placeholder="Enter your email here"
                    />
                </div>
                {isLoading ? <Loader /> : (
                <button type="submit" style={{ ...styles.submitButton, ...(hoverSubmit ? styles.submitButtonHover : {}) }}
                    onMouseEnter={() => setHoverSubmit(true)}
                    onMouseLeave={() => setHoverSubmit(false)}
                >Submit</button>
                )}
                <button type="button" style={{ ...styles.cancelButton, ...(hoverCancel ? styles.cancelButtonHover : {}) }}
                    onMouseEnter={() => setHoverCancel(true)}
                    onMouseLeave={() => setHoverCancel(false)}
                    onClick={handleCancel}
                >Cancel</button>
            </form>
            <CustomPopup isOpen={popup.isOpen} message={popup.message} onClose={closePopup} />
        </div>
    );
}

export default StripePaymentForm;
import React, { useState } from 'react';  // Removed unused useEffect

function CommentSection({ videoId, version, comments, addComment, deleteComment, onTimestampClick }) {
  const [content, setContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addComment(videoId, version, content);
    setContent('');
  };

  const parseComment = (comment) => {
    const timestampRegex = /(\d{1,2}:\d{2})/g;
    return comment.split(timestampRegex).map((part, index) => {
      if (timestampRegex.test(part)) {
        return (
          // Changed from <a> to <button> for better accessibility
          <button key={index} onClick={(e) => {
            e.preventDefault();
            const [minutes, seconds] = part.split(':').map(Number);
            onTimestampClick(minutes * 60 + seconds);
          }} style={{ background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
            {part}
          </button>
        );
      }
      return part;
    });
  };

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Add a comment"
          required
        />
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Submit
        </button>
      </form>
      <ul className="mt-4 space-y-4">
        {comments.map((comment) => (
          <li key={comment._id} className="border-b border-gray-200 pb-2 flex justify-between">
            <span>{parseComment(comment.content)}</span>
            <button onClick={() => deleteComment(comment._id)} className="bg-transparent hover:text-gray text-black py-2 px-4">
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CommentSection;
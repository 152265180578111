import React from 'react';
import styles from '../reuse/swipeButton/SwipeButton.module.css';

function SwipeButton({ showPrevious, showNext, onPrevious, onNext, disabled }) {
  return (
    <nav style={{ display: 'flex', justifyContent: 'center' }}>
      <ul style={{ display: 'flex', listStyle: 'none', padding: 0 }}>
        {showPrevious && (
          <li style={{ marginRight: '10px' }}>
            <button className={styles.link} onClick={onPrevious} disabled={disabled}>Previous</button>
          </li>
        )}
        {showNext && (
          <li>
            <button className={styles.link} onClick={onNext} disabled={disabled}>Next</button>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default SwipeButton;
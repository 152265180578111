import React, { useState } from 'react';
import VideoUpload from '../components/videoUpload';
import VideoFetch from '../components/videoFetch';
import SwipeButton from '../components/swipeButton';
import Navbar from '../components/navBar';
import Loader from '../components/loader';
import { Helmet } from 'react-helmet';
import Bookmark from '../components/bookmark'; // Import the Bookmark component

function HomePage() {
  const [showVideoFetch, setShowVideoFetch] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div>
      <Helmet>
        <title>Home Page - Share Draft becomes easier and faster</title>
        <meta name="Just Share Now" content="Share your video file, give client comment and reupload amended all in one place. No more missing and hectics, simpler and faster." />
      </Helmet>
      <div className="container mx-auto p-4 relative">
        <Navbar />
        <div className="absolute top-[-10px] left-[-60px] z-50">
          <Bookmark />
        </div>
      </div>

{/* Landing Container */}
<div className="container mx-auto p-4 justify-center">
<h2 className="text-center p-8 text-6xl font-semibold">Share draft, get comment and reupload amended all in one place.</h2>
<p className="text-center p-8 text-xl font-light">No more missing files and hectics, simpler and faster.</p>
<div className="flex flex-col items-center mt-6 mb-6 p-2">
  <button onClick={() => setShowPopup(true)} className={`py-6 px-6 border border-transparent shadow-sm text-m font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isUploading ? 'font-bold' : ''}`}>
    Get Started
  </button>
  <p className="mt-4 p-6 text-indigo-600 font-semibold cursor-pointer">See demo here ↓</p>
</div>
</div>
{/* Top Column Container */}
<div className="container mx-auto p-4 relative flex justify-center items-center">
<div className="relative p-10">
  <div className="p-2">
    <video src={process.env.PUBLIC_URL + '/JustShareNowDemo.mp4'} controls className="w-full h-full object-cover">
      Your browser does not support the video tag.
    </video>
  </div>
</div>
</div>

     {/* New Additional Container Below Video */}
     <div className="container mx-auto p-4">
        <div className="text-justify text-lg font-light p-20" style={{ fontFamily: 'Arial, sans-serif', textAlign: 'justify' }}>
          <p style={{ marginBottom: '1rem' }}>Hi, I am Sheldon 👋</p>
          <p style={{ marginBottom: '1rem' }}>I own a KOL agency and always have to deal with video sharing with clients and KOLs.</p>
          <p style={{ marginBottom: '1rem' }}>I used Google Drive but found that it cannot have comments and need to separate and upload video.</p>
          <p style={{ marginBottom: '1rem' }}>I used WeTransfer but the link always expired -,-</p>
          <p style={{ marginBottom: '1rem' }}>That's why I created JustShareNow for sharing video, for client comments and reupload adjustment draft all in one place. More importantly, no more EXPIRED!</p>
          <p style={{ marginBottom: '1rem' }}>Please leave me a message using the Contact above if you meet any problems. I would be much appreciated to hear your feedback!</p>
        </div>
      </div>

{showPopup && (
<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
  <div className="container bg-white mx-auto p-10 w-full max-w-4xl h-auto justify-center items-center">
    {/* Bottom Column Container */}
    <div className="container mx-auto p-6 relative">
      <div className="flex justify-center container mx-auto p-4">
        <div className="w-full">
          <h1 className="text-2xl font-bold mb-2 text-center">{showVideoFetch ? "Fetch Video" : "Upload Video"}</h1>
          {showVideoFetch ? <VideoFetch /> : <VideoUpload setIsUploading={setIsUploading} />}
          <SwipeButton 
            showPrevious={showVideoFetch} 
            showNext={!showVideoFetch}
            onPrevious={() => setShowVideoFetch(false)} 
            onNext={() => setShowVideoFetch(true)}
            disabled={isUploading}
          />
        </div>
      </div>
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-center z-50"> 
          <Loader />
        </div>
      )}
      <button onClick={() => setShowPopup(false)} className="absolute top-0 right-0 p-2 text-m rounded-full bg-transparent hover:bg-red-500">x</button>
    </div>
  </div>
</div>
)}
</div>
);
}

export default HomePage;


import React from 'react';

function DescriptionSection({ description }) {
  return (
    <div className="description-container bg-transparent m-2 p-4" style={{ height: 'auto', display: 'flex' }}>
      <div className="description-content flex-1 overflow-auto p-2">
        <pre style={{ whiteSpace: 'pre-wrap' }}>{description}</pre>
      </div>
    </div>
  );
}

export default DescriptionSection;
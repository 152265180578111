import React from 'react';
import { Helmet } from 'react-helmet';
import './tailwind.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/homePage';
import VideoPage from './pages/videoPage';
import NotFoundPage from './pages/notFoundPage';
import { VideoProvider } from './context/videoContext';
import { CommentProvider } from './context/commentContext';
import QnAPage from './pages/QnAPage';
import PricingPage from './pages/pricingPage';
// import SecretVideoListPage from './pages/secretVideoListingPage';

function App() {
  return (
    <VideoProvider>
      <CommentProvider>
        <div className="App">
        <Helmet>
            <title>Just Share Now | Video Files Share, Get Comments and Amend in One Place</title>
            <meta content="Just share your video file, give client comment and reupload amended all in one place. No more missing and hectics, simpler and faster." />
          </Helmet>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/video/:id" element={<VideoPage />} />
            <Route path="/QnA" element={<QnAPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </CommentProvider>
    </VideoProvider>
  );
}

export default App;

import React from 'react';
import { createRoot } from 'react-dom/client';
import './tailwind.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

const app = (
  <Router>
    <App />
  </Router>
);

if (process.env.NODE_ENV === 'development') {
  root.render(<React.StrictMode>{app}</React.StrictMode>);
} else {
  root.render(app);
}
import React, { useState } from 'react';
import './AnimatedButton.css'; // Make sure the path is correct

const AnimatedButton = () => {
    const [showInput, setShowInput] = useState(false);
    const [email, setEmail] = useState('');

    const handleButtonClick = () => {
        setShowInput(!showInput);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <div className="btn-container">
            {showInput && (
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    className={`email-input ${showInput ? 'active' : ''}`}
                />
            )}
            <button className="notifyBtn" onClick={handleButtonClick}>
                {showInput ? 'Submit' : 'Contact Sales'}
            </button>
        </div>
    );
};

export default AnimatedButton;
import React, { useRef, forwardRef, useImperativeHandle } from 'react';

const VideoPlayer = forwardRef(({ videoUrl }, ref) => {
  const videoRef = useRef(null);

  useImperativeHandle(ref, () => ({
    seekToTime(time) {
      if (videoRef.current) {
        videoRef.current.currentTime = time;
        videoRef.current.play();
      }
    }
  }));

  return (
    <div style={{ position: 'relative', top: 10 }}>
      <video ref={videoRef} key={videoUrl} width="400" height="200" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

export default VideoPlayer;
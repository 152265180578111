import React, { createContext, useState, useCallback, useMemo } from 'react';
import axios from 'axios';

export const CommentContext = createContext();

export const CommentProvider = ({ children }) => {
  const [comments, setComments] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL; // Use environment variable

  const fetchComments = useCallback(async (videoId, version) => {
    try {
      const response = await axios.get(`${baseURL}/api/comments/${videoId}/${version}`);
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  }, [baseURL]);

  const addComment = useCallback(async (videoId, version, content) => {
    if (!videoId || !version) {
      console.error('Video ID or Version is undefined');
      return;
    }
    try {
      const response = await axios.post(`${baseURL}/api/comments/add/${videoId}/${version}`, { content });
      setComments(prevComments => [...prevComments, response.data]);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  }, [baseURL]);

  const deleteComment = useCallback(async (commentId) => {
    try {
      await axios.delete(`${baseURL}/api/comments/${commentId}`);
      setComments(prevComments => prevComments.filter(comment => comment._id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  }, [baseURL]);

  const value = useMemo(() => ({
    comments,
    fetchComments,
    addComment,
    deleteComment
  }), [comments, fetchComments, addComment, deleteComment]);

  return (
    <CommentContext.Provider value={value}>
      {children}
    </CommentContext.Provider>
  );
};
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function VideoFetch() {
  const [videoId, setVideoId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Inside your component
// useEffect(() => {
//   console.log('Component rendered or re-rendered');
// }, []); // Empty dependency array means this will run only once after the initial render

  const handleInputChange = (e) => {
    setVideoId(e.target.value);
  };

  const handleButtonClick = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log('Button clicked with video ID:', videoId);
    if (videoId.trim()) {
      console.log('Video ID:', videoId); // Debug: Log the video ID
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        console.log('API Base URL:', baseURL); // Debug: Log the API base URL
        const response = await fetch(`${baseURL}/api/videos/${videoId}`);
        console.log('API Response:', response); // Debug: Log the fetch response

        if (!response.ok) {
          throw new Error('Invalid video ID format or video not found');
        } else {
          console.log('Fetch successful, navigating to video:', videoId); // Debug: Confirm successful fetch before navigation
          navigate(`/video/${videoId}`);
        }
      } catch (error) {
        console.error('Error fetching video:', error.message); // Debug: Log any errors
        setErrorMessage(error.message);
        return;
      }
    } else {
      console.log('Button click ignored: No Video ID entered');
    }
  };

  return (
    <div className="flex justify-center items-center h-90">
      <form className="space-y-4 bg-white p-6 rounded-lg shadow-lg ">
        <div>
          <label className="block text-sm font-medium text-gray-700">Enter Video ID</label>
          <input
            type="text"
            value={videoId}
            onChange={handleInputChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Enter Video ID"
            required
          />
        </div>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <div className="flex justify-center w-full">
          <button
            type="button"
            onClick={handleButtonClick}
            className="py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Navigate to Video
          </button>
        </div>
      </form>
    </div>
  );
}

export default VideoFetch;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StripePaymentForm from './stripePaymentForm';

const PricingForm = () => {
  // Define inline styles
  const styles = {
    fullScreenContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#333',
      color: '#fff',
      fontFamily: 'Gill Sans',
      fontSize: '16px',
      padding: '30px',
      fontWeight: 'bold'
    },
    smalltitle: {
      fontSize: '14px',
      fontFamily: 'monospace',
      color: '#ffd700', // gold color for the promotional text
      marginBottom: '10px'
    },
    title: {
      fontSize: '28px', // Larger font size for the main title
      fontWeight: 'bold',
      marginBottom: '50px',
      color: '#fff'
    },
    subtitle: {
      fontSize: '14px',
      fontFamily: 'monospace',
      color: '#4CAF50', // Green color for the promotional text
      marginBottom: '40px'
    },
    pricingCards: {
      display: 'flex',
      gap: '20px'
    },
    card: {
      backgroundColor: '#444',
      padding: '20px',
      borderRadius: '8px',
      width: '300px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    },
    planName: {
      fontSize: '20px',
      color: '#ffd700',
      marginBottom: '10px'
    },
    price: {
      fontSize: '23px',
      marginBottom: '20px'
    },
    oldPrice: {
      textDecoration: 'line-through',
      marginBottom: '10px',
      color: '#ccc'
    },
    features: {
      textAlign: 'left',
      listStyle: 'none',
      padding: '0',
      marginBottom: '20px'
    },
    featureItem: {
      marginBottom: '10px',
      display: 'flex',
      fontSize: '15px',
      alignItems: 'center'
    },
    tickIcon: {
      marginRight: '5px',
      color: '#4CAF50'
    },
    btn: {
      backgroundColor: '#4F46E5',
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      cursor: 'pointer',
      borderRadius: '5px',
      width: '100%',
    },
    btnHover: {
      backgroundColor: '#3730a3',
    },
    btnDisabled: {
      backgroundColor: '#718096',
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      cursor: 'not-allowed',
      borderRadius: '5px',
      width: '100%'
    },
    popular: {
      border: '2px solid #ffd700'
    },
    btnReturn: {
      backgroundColor: '#ffd700', // Initial color
      color: '#fff',
      border: 'none',
      padding: '10px', // Circular padding
      cursor: 'pointer',
      borderRadius: '50%', // Fully round shape
      width: '40px', // Fixed width for circle
      height: '40px', // Fixed height for circle
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20px', // Add space between form and button
      transition: 'background-color 0.3s', // Smooth transition for hover effect
    },
    btnReturnHover: {
      backgroundColor: '#71717a', // gray color on hover
    },
  };

  const [hoverUpgrade, setHoverUpgrade] = useState(false);
  const [hoverReturn, setHoverReturn] = useState(false);
  const navigate = useNavigate();
  const [showPayment, setShowPayment] = useState(false);

  const handleReturn = () => {
    navigate('/', { replace: true });
  };

  const handleUpgradeProClick = () => {
    setShowPayment(true); // Ensure this is set to true when the button is clicked
  };

  const handlePaymentSuccess = (paymentMethodId) => {
    console.log('Payment successful:', paymentMethodId);
    setShowPayment(false);
    navigate('/pricing', { replace: true });
  };

  const handleClose = () => {
    setShowPayment(false); // Ensure this is set to false when the popup is closed
  };

  return (
    <div style={styles.fullScreenContainer}>
        <h1 style={styles.smalltitle}>Pricing</h1>
      <h1 style={styles.title}>Save time to create links, find links and repetitive messages!</h1>
      {/* <p style={styles.subtitle}>
        <span style={{ color: '#4CAF50' }}>💰 $10 off for the first 100 customers (50 left)</span>
      </p> */}
      <div style={styles.pricingCards}>
        <div style={styles.card}>
          <h2 style={styles.planName}>Starter</h2>
          <p style={styles.price}>
            <span style={styles.oldPrice}>$49</span> $0 USD
          </p>
          <ul style={styles.features}>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>500MB upload limit for video file</li>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>No authentication needed</li>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>500MB storage per video file</li>
            <li style={styles.oldPrice}><span style={styles.tickIcon}>x</span>Photo file is supported</li>
            <li style={styles.oldPrice}><span style={styles.tickIcon}>x</span>Fast upload speed</li>
          </ul>
          <button style={styles.btnDisabled} disabled={true}>FREE</button>
        </div>
        <div style={{ ...styles.card, ...styles.popular }}>
          <h2 style={styles.planName}>Pro</h2>
          <p style={styles.price}>
            <span style={styles.oldPrice}>$99</span> $18 USD
          </p>
          <ul style={styles.features}>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>Up to 2GB upload limit for video file</li>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>No authentication needed</li>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>2GB storage per video file</li>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>Photo file is supported</li>
            <li style={styles.featureItem}><span style={styles.tickIcon}>✔</span>Fast upload speed</li>
          </ul>
          <button style={{ ...styles.btn, ...(hoverUpgrade ? styles.btnHover : {}) }}
            onMouseEnter={() => setHoverUpgrade(true)}
            onMouseLeave={() => setHoverUpgrade(false)}
            onClick={handleUpgradeProClick} // Show payment form on click
          >Upgrade Pro</button>
        </div>
        {showPayment && (
            <div style={styles.overlay}>
            <StripePaymentForm onSuccess={handlePaymentSuccess} onClose={handleClose} />
        </div>
      )}
      </div>
      <button style={{ ...styles.btnReturn, ...(hoverReturn ? styles.btnReturnHover : {}) }}
        onMouseEnter={() => setHoverReturn(true)}
        onMouseLeave={() => setHoverReturn(false)}
        onClick={handleReturn}>
        <span style={{ fontSize: '16px', color: 'black' }}>←</span>
      </button>
    </div>
  );
};

export default PricingForm;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../sources/JustShareNow.png';

function NotFoundPage() {
  const navigate = useNavigate();

  // Define the button style
  const btnReturnStyle = {
    backgroundColor: '#ffd700', // Initial color
    color: '#fff',
    border: 'none',
    padding: '10px', // Circular padding
    cursor: 'pointer',
    borderRadius: '50%', // Fully round shape
    width: '40px', // Fixed width for circle
    height: '40px', // Fixed height for circle
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px', // Add space between form and button
    transition: 'background-color 0.3s', // Smooth transition for hover effect
    marginLeft: 'auto', // Center the button horizontally
    marginRight: 'auto'
  };

  // Function to navigate back to the homepage
  const handleReturn = () => {
    navigate('/');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <img src={logo} alt="Logo" style={{ width: '150px', margin: '0 auto' }} />
      <h1 style={{ fontSize: '48px', fontWeight: 'bold', fontFamily: 'Comic Sans MS, cursive, sans-serif' }}>404 - Not Found</h1>
      <p style={{ fontSize: '24px', fontFamily: 'Comic Sans MS, cursive, sans-serif' }}>Oops! The page you are looking for does not exist.</p>
      <button style={btnReturnStyle} onClick={handleReturn}>
        <span style={{ fontSize: '16px', color: 'black' }}>←</span>
      </button>
    </div>
  );
}

export default NotFoundPage;
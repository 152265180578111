import React, { useState } from 'react';
import axios from 'axios';
import Loader from './loader';
import { useNavigate } from 'react-router-dom';
import CustomPopup from './customPopup';

function ButtonNav({ videoId, updateDescription }) {
  const navigate = useNavigate();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showReuploadPopup, setShowReuploadPopup] = useState(false);
  const [showUpdateDescriptionPopup, setShowUpdateDescriptionPopup] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [newDescription, setNewDescription] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [popup, setPopup] = useState({ isOpen: false, message: '' });
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const closeAllPopups = () => {
    setPopup({ isOpen: false, message: '' });
    setShowUpdateDescriptionPopup(false);
    setShowReuploadPopup(false);
    setShowConfirmationPopup(false);
  };

  const closePopup = () => {
    setPopup({ isOpen: false, message: '' });
    navigate('/', { replace: true });
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const allowedTypes = ['video/mp4', 'video/quicktime'];
      if (!allowedTypes.includes(selectedFile.type)) {
        setPopup({ isOpen: true, message: 'Only MP4 and MOV video files are allowed.' });
        return;
      }
      if (selectedFile.size > 1073741824) {
        setPopup({ isOpen: true, message: 'File size cannot exceed 1GB.' });
        return;
      }
      setFile(selectedFile);
    }
  };

  const handleUpdateDescription = async () => {
    if (!newDescription) {
      setPopup({ isOpen: true, message: 'Please enter a caption.' });
      return;
    }
  
    try {
      const response = await axios.put(`${baseURL}/api/videos/${videoId}/update-description`, {
        description: newDescription,
      });
      if (response.status === 200) {
        setPopup({ isOpen: true, message: 'Caption updated successfully.' });
        updateDescription(newDescription);
        setNewDescription('');
        setShowUpdateDescriptionPopup(false);
      } else {
        throw new Error('Failed to update caption');
      }
    } catch (error) {
      console.error('Error updating caption:', error);
      setPopup({ isOpen: true, message: 'Failed to update the caption.' });
    }
  };

  const openUpdateDescriptionPopup = () => {
    setShowUpdateDescriptionPopup(true);
  };

  const handleReupload = async () => {
    if (!file) {
      setPopup({ isOpen: true, message: 'Please select a file to upload.' });
      return;
    }
  
    const allowedTypes = ['video/mp4', 'video/quicktime'];
    if (!allowedTypes.includes(file.type)) {
      setPopup({ isOpen: true, message: 'Only MP4 and MOV video files are allowed.' });
      return;
    }
  
    setIsUploading(true);
  
    const formData = new FormData();
    formData.append('video', file);
    if (newDescription) formData.append('description', newDescription);
  
    try {
      const response = await axios.post(`${baseURL}/api/videos/${videoId}/reupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
  
      if (response.status === 200) {
        setPopup({ isOpen: true, message: 'Video reuploaded successfully.' });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setPopup({ isOpen: true, message: error.response.data.message });
      } else {
        console.error('Error reuploading video:', error);
        setPopup({ isOpen: true, message: 'Failed to reupload the video.' });
      }
    } finally {
      setShowReuploadPopup(false);
      setFile(null);
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleMarkAsComplete = () => {
    setShowConfirmationPopup(true);
  };

  const handleConfirm = async () => {
    if (confirmationText === 'I am sure') {
      setIsDeleting(true);
      try {
        const response = await axios.delete(`${baseURL}/api/videos/${videoId}/delete-all`);
        console.log('Delete response:', response.data);
        setPopup({
          isOpen: true,
          message: 'Video and all associated comments have been deleted successfully.',
          onClose: () => {
            console.log("Attempting to navigate to homepage after successful deletion.");
            closePopup();
          }
        });
      } catch (error) {
        console.error('Error deleting video and comments:', error);
        setPopup({
          isOpen: true,
          message: 'Failed to delete the video and comments. Click close to retry.',
          onClose: () => {
            console.log("Attempting to navigate to homepage after failed deletion.");
            closePopup();
          }
        });
      } finally{
        setIsDeleting(false);
      }
    } else {
      setError('Please type "I am sure" to confirm.');
      setPopup({
        isOpen: true,
        message: 'Confirmation failed. Please type "I am sure" to confirm. Click close to retry.',
        onClose: () => {
          console.log("Attempting to navigate to homepage after confirmation failure.");
          closePopup();
        }
      });
    }
  };

  const handleCancel = () => {
    setShowReuploadPopup(false);
    setShowConfirmationPopup(false);
    setShowUpdateDescriptionPopup(false);
    setConfirmationText('');
    setError('');
    setNewDescription('');
    setFile(null);
  };

  const openReuploadPopup = () => {
    setShowReuploadPopup(true);
  };

  return (
    <div className="flex justify-center space-x-4 mt-4">
      <CustomPopup 
      isOpen={popup.isOpen} 
      message={popup.message} 
      onClose={closeAllPopups}/>
      <button className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
       onClick={openUpdateDescriptionPopup}>
        Update Description
      </button>
      <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
       onClick={openReuploadPopup}>
        Upload New Draft
      </button>
      <button className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
       onClick={handleMarkAsComplete}>
        Mark as Complete
      </button>

      {isDeleting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <Loader progress={uploadProgress} /> 
        </div>
      )}

      {showReuploadPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-4xl">
            <h2 className="text-xl font-bold mb-4">Upload a New Draft Video</h2>
            <input type="file" onChange={handleFileChange} className="mb-4 w-full p-3 border border-gray-300 rounded" />
            <div className="flex justify-end space-x-4">
              <button className="bg-gray-500 text-white px-6 py-3 rounded hover:bg-gray-600 focus:outline-none" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className={`inline-flex items-center justify-center py-2 px-4 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleReupload}
                disabled={isUploading || !file}
              >
                {isUploading ? <Loader progress={uploadProgress} /> : 'Upload'}
              </button>
            </div>
          </div>
        </div>
      )}

      {showUpdateDescriptionPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-4xl">
            <h2 className="text-xl font-bold mb-4">Update Caption</h2>
            <p className="text-sm mb-4 text-gray-600">After updating, the latest caption will replace the previous one.</p>
            <input
              type="text"
              placeholder="Enter new caption"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              className="mb-4 w-full p-3 border border-gray-300 rounded"
            />
            <div className="flex justify-end space-x-4">
              <button className="bg-gray-500 text-white px-6 py-3 rounded hover:bg-gray-600 focus:outline-none" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="inline-flex items-center justify-center py-2 px-4 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={handleUpdateDescription}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmationPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg w-96">
            <h2 className="text-lg font-bold mb-2">Are you sure you want to mark as complete?</h2>
            <p className="mb-4">System will delete the video permanently.</p>
            <input
              type="text"
              className="border p-2 w-full mb-2"
              placeholder="I am sure"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
            {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
            <div className="flex justify-end space-x-4">
              <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none" onClick={handleCancel}>
                No
              </button>
              <button className="inline-flex items-center py-2 px-4 mx-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
               onClick={async () => {
                 try {
                   await handleConfirm();
                   navigate('/', { replace: true });
                 } catch (error) {
                   console.error('Confirmation failed:', error);
                 }
               }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ButtonNav;

// 66acd5081787af8cf13b496f
// 66af46c68a24f258ee2e83c0
// 66c06b3251a44f4b997b4a22